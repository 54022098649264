import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Play & Earn',
    paragraph: 'Using your owned NFTs, you can dive in to the vast raider universe where you can earn and have fun!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Customizable NFTs
                  </div>
                <h3 className="mt-0 mb-12">
                Your own Build
                  </h3>
                <p className="m-0">
                  The player has the absolute ownership and control of his Raider. Each raider will have its own stats, skills, elemental affinity and items equipped which are set by the player/owner.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Insert a Gen2 Raider GIF"
                  width={528}
                  height={396} />
              </div>
            </div> */}

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Anti-Whale
                  </div>
                <h3 className="mt-0 mb-12">
                  Player-First
                  </h3>
                <p className="m-0">
                  The rewards system highly favors 'true players' instead of high-capital casuals. The depth of the game's micro and macro gameplay and mechanics make it very rewarding for players who build their raiders carefully.
                  <br/>
                  <br/>
                  On the macro gameplay level, strategy and cooperation will prove to be more effective than the mere number of participants in a team/faction.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/map.jpeg')}
                  alt="Insert MAP gif"
                  width={528}
                  height={396} />
              </div>
            </div>

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Highly sustainable
                  </div>
                <h3 className="mt-0 mb-12">
                  Token Economics
                  </h3>
                <p className="m-0">
                  To ensure the growth of the raiders ecosystem, a giant chunk of the token supply is allocated for game rewards that would be emitted in a sustainable pace for a period of around 4 years.
                  <br/>
                  <br/>
                  Pair that with the liquidity that grows with user-adoption, the value of the token is expected to have a steady growth over time especially in the long-term.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Insert the token allocations pie"
                  width={528}
                  height={396} />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;