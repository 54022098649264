import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';

import RaidersLogo from '../components/sections/raiders-logo';
import TokenEconomics from '../components/sections/TokenEconomics';
import Partners from '../components/sections/Partners';
import Marketplaces from '../components/sections/Marketplaces';
import Raiderverse from '../components/sections/Raiderverse';

const About = () => {

  return (
    <>
      <RaidersLogo className="illustration-section-01 mb-0 pb-0" />
      <Hero className="p-0 m-0" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <TokenEconomics topDivider/>
      <Roadmap topDivider />
      {/* <Raiderverse /> */}
      <Partners bottomDivider/>
      <Marketplaces />
      <Cta />

      {/* COLLECTIONS */}
      {/* LORE */}
      {/* NEWS & GUIDES */}

      {/* <Cta split /> */}
    </>
  );
}

export default About;