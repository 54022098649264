import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Raiders Core Team',
    paragraph: 'A group of crypto gremlins who are really in it for the tech.'
  };

  const sectionFooter = {
    title: 'Want to work with us?',
    paragraph: 'Hit us up on discord.'
  };

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className={tilesClasses}>

            

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Thoriyon/Thoriyon-Ranger.png')}
                      alt="Features tile icon 04"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    xBeanDoggx
                    </h4>
                  <p className="m-0 text-sm">
                    Community Manager / Marketing Head / Business Development
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Skouro/Skouro-Tank.png')}
                      alt="Features tile icon 05"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Krang
                    </h4>
                  <p className="m-0 text-sm">
                    Creative Director / Talent Scout & Recruiting
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Deterra/Deterra-Mage.png')}
                      alt="Features tile icon 05"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    SgtRadha
                    </h4>
                  <p className="m-0 text-sm">
                    Creative Director / Writer / Graphic Artist
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Deterra/Deterra-Ranger.png')}
                      alt="Features tile icon 06"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Gigzilla
                    </h4>
                  <p className="m-0 text-sm">
                    Artist
                    </p>
                </div>
              </div>
            </div>

            

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Deterra/Deterra-Berserker.png')}
                      alt="Features tile icon 02"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Master Finn
                    </h4>
                  <p className="m-0 text-sm">
                    Game Architect / Unity Dev
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Thoriyon/Thoriyon-Berserker.png')}
                      alt="Features tile icon 03"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Kelxety
                    </h4>
                  <p className="m-0 text-sm">
                    Front-end Dev / Unity Dev
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/avatars/Ramelan/Ramelan-Berserker.png')}
                      alt="Features tile icon 01"
                      width={256}
                      height={256} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    SgtChiliPapi
                    </h4>
                  <p className="m-0 text-sm">
                    Lead Dev / Smart Contracts
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <SectionHeader data={sectionFooter} className="center-content" />
      </div>
    </section>
  );
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;