import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TokenEconomics = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Token Economics',
    // paragraph: 'Insert token economics write-up here'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Highly-Sustainable 
                  </div>
                <h3 className="mt-0 mb-12">
                Game Rewards
                  </h3>
                <p className="m-0">
                To ensure the growth of the raiders ecosystem, a giant chunk of the token supply is allocated for game rewards that would be emitted in an increasing manner for a period of around 4 years.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/TokenAllocations.png')}
                  alt="Insert carousel of pie chart, "
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Stable
                  </div>
                <h3 className="mt-0 mb-12">
                  Natural Liquidity
                  </h3>
                <p className="m-0">
                  One must first supply liquidity in $R4ID-$FTM and use the LP token to mint a Raider. If the owner of such raider subsequently decides to burn his raider, he can reclaim his supplied liquidity for a percentage depending upon the Raider's Level. The unredeemed LP portion will remain in the liquidity pool forever. <br/><br/> If the Raider is at max level upon burning, the owner can claim up to 200% of the liquidity supplied.                  
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/RewardsLiquidity.png')}
                  alt="Carousel of liquidity addition, token emission, etc."
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Free Market
                  </div>
                <h3 className="mt-0 mb-12">
                  In-game Economy
                  </h3>
                <p className="m-0">
                  The game would have its own defi ecosystem which would provide an avenue to trade/craft in-game items and equipment.
                  <br/><br/>
                  Apart from earning tokens from raiding, other ways of earning can be adopted by the player such as refining, crafting, upgrading/repairing equipment or simply trading within our own AMMs for in-game commodities.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/items.png')}
                  alt="Insert items gif"
                  width={264}
                  height={198} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

TokenEconomics.propTypes = propTypes;
TokenEconomics.defaultProps = defaultProps;

export default TokenEconomics;