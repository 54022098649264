import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Roadmap = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Development Roadmap',
    paragraph: 'We will continue to expand the game mechanics, its contents, and the gameverse while zooming in on the gameplay and individual player experience.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <span className="testimonial-item-name text-color-high m-0 p-0">Launch</span>
                <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider">
                  <ul>
                    <li><p className="text-xs mb-0">Initial Raider Offering (1st Gen NFTs)</p></li>
                    <li><p className="text-xs mb-0">Game Launch (PVP) 4 rounds</p></li>
                    <li><p className="text-xs mb-0">Initial Dex Offering</p></li>
                    <li><p className="text-xs mb-0">Raider Basic Stats</p></li>
                    <li><p className="text-xs mb-0">In-game Defi Structure</p></li>
                    <ul>
                      <li><p className="text-xs mb-0">Item Farming (Dungeons)</p></li>
                      <li><p className="text-xs mb-0">Item Refinery (Refinery Equipment)</p></li>
                      <li><p className="text-xs mb-0">Item Crafting (Crafting Tools)</p></li>
                      <li><p className="text-xs mb-0">Item Upgrades/Repairs (Upgrading Tools)</p></li>
                      <li><p className="text-xs mb-0">In-game marketplace (Swaps & NFT Market) </p></li>
                    </ul>
                  </ul>
                  <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider"></div>
                  <span className="testimonial-item-name text-color-high m-0 p-0">Q1 - Q3 2022</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <span className="testimonial-item-name text-color-high m-0 p-0">Build</span>
                <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider">
                  <ul>
                  <li><p className="text-xs mb-0">Elemental Affinities</p></li>
                    <li><p className="text-xs mb-0">Raider Talent Trees</p></li>
                    <li><p className="text-xs mb-0">Raider Skills</p></li>
                    <li><p className="text-xs mb-0">Experience and Leveling</p></li>
                    <li><p className="text-xs mb-0">Raider Tiers</p></li>
                    <li><p className="text-xs mb-0">2nd Gen NFTs (Generative)</p></li>
                    <li><p className="text-xs mb-0">Map-based Faction Wars</p></li>
                    <li><p className="text-xs mb-0">Animated Battlers</p></li>
                    <li><p className="text-xs mb-0">Expansions</p></li>
                  </ul>
                  <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider"></div>
                  <span className="testimonial-item-name text-color-high m-0 p-0">Q3 2022 - Q4 2023</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <span className="testimonial-item-name text-color-high m-0 p-0">Turn-over</span>
                <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider">
                  <ul>
                    <li><p className="text-xs mb-0">DAO voting system</p></li>
                    <li><p className="text-xs mb-0">DAO vote mining</p></li>
                    <li><p className="text-xs mb-0">DAO tooling</p></li>
                    <li><p className="text-xs mb-0">Handing over of keys to the DAO</p></li>
                  </ul>
                  <div className="testimonial-item-footer text-xs mt-4 mb-0 has-top-divider"></div>
                  <span className="testimonial-item-name text-color-high m-0 p-0">Q1 - Q2 2024</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;