import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';

import RaidersLogo from '../components/sections/raiders-logo';
import TokenEconomics from '../components/sections/TokenEconomics';
import Partners from '../components/sections/Partners';
import Marketplaces from '../components/sections/Marketplaces';
import Team from '../components/sections/Team';

const Home = () => {

  return (
    <>
      <Team className="p-0 m-0" />
      {/* TEAM */}
      {/* COLLECTIONS */}
      {/* LORE */}
      {/* NEWS & GUIDES */}

      {/* <Cta split /> */}
    </>
  );
}

export default Home;